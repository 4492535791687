import { emailPattern } from './../constant/constant'
interface ISignUp {
  email?: string
  tNc?: boolean
}

interface IErrorSingUp {
  email?: string
  tNc?: string
}

interface ISignIn {
  email?: string
  password?: string
  rememberCheck?: boolean
}

interface IErrorSingIn extends ISignIn {}

interface ICreatePassword {
  password?: string
}

interface IErrorCreatePassword extends ICreatePassword {}

interface ICode {
  code?: string
}

interface IDeviceName {
  deviceName?: string
}

interface IErrorCode extends ICode {}

interface IErrorDeviceName extends IDeviceName {}

interface IResetPwd {
  oldpassword?: string
  newpassword?: string
}

interface IErrorResetPwd extends IResetPwd {}

export const signUpValidate = (values: ISignUp) => {
  const errors = {} as IErrorSingUp
  if (!values.email) {
    errors.email = 'Required'
  } else if (!emailPattern.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  if (values.tNc === false) {
    errors.tNc = 'Required'
  }
  return errors
}

export const signInValidate = (values: ISignIn) => {
  const errors = {} as IErrorSingIn
  if (!values.email) {
    errors.email = 'Required'
  } else if (!emailPattern.test(values.email)) {
    errors.email = 'Invalid email address'
  }
  if (!values.password) {
    errors.password = 'Required'
  }

  return errors
}

export const newPasswordValidate = (values: ICreatePassword) => {
  const errors = {} as IErrorCreatePassword
  if (!values.password) {
    errors.password = 'Required'
  } else if (values.password.length <= 4) {
    errors.password = 'Should be minimum 4 characters'
  }
  return errors
}

export const codeValidate = (values: ICode) => {
  const errors = {} as IErrorCode
  if (!values.code) {
    errors.code = 'Required'
  } else if (values.code.length !== 5) {
    errors.code = 'Invalid code'
  }
  return errors
}

export const deviceNameValidate = (values: IDeviceName) => {
  const errors = {} as IErrorDeviceName
  if (!values.deviceName) {
    errors.deviceName = 'Required'
  }
  return errors
}

export const resetPasswordValidate = (values: IResetPwd) => {
  const errors = {} as IErrorResetPwd
  if (!values.oldpassword) {
    errors.oldpassword = 'Required'
  } else if (!values.newpassword) {
    errors.newpassword = 'Required'
  } else if (values.newpassword.length < 4) {
    errors.newpassword = 'Minimum 4 character is required'
  }
  return errors
}
