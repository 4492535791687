import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import { axiosAuth, SetAuthCookie } from 'esstart-components'

export const authSliceName = 'auth'

const initialState = {
  username: Cookies.get('username') || '',
  accessToken: Cookies.get('accessToken') || '',

  refreshToken: Cookies.get('refreshToken') || '',
  loading: false,
  userId: Cookies.get('userId') || '',
  noOfDevices: '',
  name: '',
  verifyStatus: 0,
  resetEmailStatus: false,
}

interface SignInPayload {
  email: string
  password: string
  reCaptcha: string
}

interface SignUpPayload {
  source: string
  name: string
  email: string
  password: string
  productId: string
  reCaptcha: string
  testProduct?: string
  phoneNum?: string
}

interface ResendEmailPayload {
  userName: string
}

export const signInUser = createAsyncThunk(
  'auth/signin',
  async (args: SignInPayload, { rejectWithValue }) => {
    const { email, password, reCaptcha } = args
    try {
      Cookies.set('username', email)
      const res = await axiosAuth.post('/auth-tokens', {
        username: email,
        password: password,
        reCaptcha,
      })
      if (res.status === 200) {
        SetAuthCookie(res?.data?.data)
      }
      return res.data
    } catch (err: any) {
      throw rejectWithValue(err.response.data)
    }
  }
)

export const resendEmail = createAsyncThunk(
  'auth/resendEmail',
  async (args: ResendEmailPayload, { getState, rejectWithValue }) => {
    const { userName } = args
    const store: any = getState()
    try {
      const res = await axiosAuth.post(
        '/resend-link',
        { userName: userName },
        {
          headers: {
            Authorization: store?.auth?.accessToken,
          },
        }
      )
      return res.data
    } catch (err: any) {
      throw rejectWithValue(err.response.data)
    }
  }
)

export const signUpUser = createAsyncThunk(
  'auth/signup',
  async (args: SignUpPayload, { rejectWithValue }) => {
    const {
      source,
      name,
      email,
      password,
      productId,
      reCaptcha,
      testProduct,
      phoneNum,
    } = args
    try {
      const res = await axiosAuth.post('/register', {
        source,
        name,
        email,
        password,
        productId,
        reCaptcha,
        testProduct,
        phone_number: phoneNum,
      })
      if (res.status === 201) {
        SetAuthCookie(res?.data?.data)
      }
      Cookies.set('username', email)
      return { ...res.data, email }
    } catch (err: any) {
      throw rejectWithValue(err.response.data)
    }
  }
)

export const socialLoginSuccess = createAsyncThunk(
  'auth/socialLoginSuccess2',
  async (args: any) => {
    const { token, expiry } = args
    Cookies.set('accessToken', token, { expires: new Date(expiry * 1000) })
    return { token }
    // console.log(token);
  }
)

export const confirmUser = createAsyncThunk(
  'auth/confirmUser',
  async (args: any, { rejectWithValue }) => {
    const { username, confirmationCode } = args
    try {
      const res = await axiosAuth.put('/user-confirmation', {
        username,
        confirmationCode,
      })
      if (res.status === 200) {
        SetAuthCookie(res?.data?.data)
      }
      return { ...res.data }
    } catch (err: any) {
      throw rejectWithValue(err.response.status)
    }
  }
)

export const confirmEmailForgotPwd = createAsyncThunk(
  'auth/forgot-password',
  async (args: any, { rejectWithValue }) => {
    const { username } = args
    try {
      Cookies.set('username', username)
      const res = await axiosAuth.put('/forgot-password', {
        username,
      })
      // if (res.status === 200) {
      //   SetAuthCookie(res?.data?.data);
      // }
      return { ...res.data }
    } catch (err: any) {
      throw rejectWithValue(err.response.status)
    }
  }
)

export const resetPassword = createAsyncThunk(
  'auth/reset-password',
  async (args: any, { rejectWithValue }) => {
    const { username, newPassword, confirmationCode } = args
    try {
      const res = await axiosAuth.put('/forget-password-confirmation', {
        username,
        newPassword,
        confirmationCode,
      })
      // if (res.status === 200) {
      //   SetAuthCookie(res?.data?.data);
      // }
      return { ...res.data }
    } catch (err: any) {
      throw rejectWithValue(err.response.status)
    }
  }
)
export const changePassword = createAsyncThunk(
  'auth/change-password',
  async (args: any, { getState, rejectWithValue }) => {
    const store: any = getState()
    try {
      const res = await axiosAuth.put(
        '/change-password',
        {
          ...args,
        },
        {
          headers: {
            Authorization: store?.auth?.accessToken,
          },
        }
      )
      // if (res.status === 200) {
      //   SetAuthCookie(res?.data?.data);
      // }
      return { ...res.data }
    } catch (err: any) {
      console.log(err)
      throw rejectWithValue(err.response.status)
    }
  }
)

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(signInUser.pending, (state) => {
      state.loading = true
    })
    builder.addCase(signInUser.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(signInUser.fulfilled, (state, action) => {
      state.loading = false
      state.username = action.payload?.data?.username
      state.accessToken =
        action.payload?.data?.AuthenticationResult?.AccessToken
      state.refreshToken =
        action.payload?.data?.AuthenticationResult?.RefreshToken
    })
    builder.addCase(confirmUser.pending, (state) => {
      state.loading = true
    })
    builder.addCase(confirmUser.rejected, (state, action: any) => {
      state.loading = false
      state.verifyStatus = action.payload
    })
    builder.addCase(confirmUser.fulfilled, (state, action) => {
      state.loading = false
      state.username = action.payload?.data?.username
      state.accessToken =
        action.payload?.data?.AuthenticationResult?.AccessToken
      state.refreshToken =
        action.payload?.data?.AuthenticationResult?.RefreshToken
    })
    builder.addCase(signUpUser.fulfilled, (state, action) => {
      state.loading = false
    })
    builder.addCase(socialLoginSuccess.fulfilled, (state, action) => {
      state.accessToken = action.payload.token
    })
    builder.addCase(resendEmail.pending, (state) => {
      state.loading = true
      state.resetEmailStatus = false
    })
    builder.addCase(resendEmail.rejected, (state) => {
      state.loading = false
      state.resetEmailStatus = false
    })
    builder.addCase(resendEmail.fulfilled, (state) => {
      state.loading = false
      state.resetEmailStatus = true
    })
    builder.addCase(changePassword.pending, (state) => {
      state.loading = true
    })
    builder.addCase(changePassword.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(changePassword.fulfilled, (state) => {
      state.loading = false
    })
    builder.addCase(confirmEmailForgotPwd.pending, (state) => {
      state.loading = true
    })
    builder.addCase(confirmEmailForgotPwd.rejected, (state) => {
      state.loading = false
    })
    builder.addCase(confirmEmailForgotPwd.fulfilled, (state) => {
      state.loading = false
    })
  },
})

export const authReducer = authSlice.reducer
